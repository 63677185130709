import React, { Component, forwardRef, useContext } from 'react';
import isArray from 'lodash/isArray';

// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from './InfiniteScrollComponent';

import Query from './Query';

import ApiContext from '../context/Api';

InfiniteScroll.propTypes = {};

export default class InfiniteScrollQuery extends Component {
  static defaultProps = {
    limit: 12,
    getUrl: (props, skip, state) => (
      `${props.url}?force=${state.force}&query=${
        encodeURIComponent(JSON.stringify(
          props.getQueryParams({
            skip,
            where: props.getWhereConditions(props, skip),
            limit: props.limit,
          }, props)
        ))}`
    ),
    // eslint-disable-next-line arrow-parens
    getQueryParams: (params/* props */) => params,
    getWhereConditions: (/* props, skip */) => ({}),
    shouldReload: (/* currentProps, newProps */) => false,
    renderItem: (/* item, index, props */) => null,
    renderEmpty: (/* props */) => null,
    extractData: response => response,
    rerender: false,
  }

  constructor(props) {
    super(props);
    this.state = { exhausted: false, force: 0 };
  }

  componentDidUpdate(oldProps) {
    if (this.props.shouldReload(oldProps, this.props)) {
      this.reload();
    }
  }

  reload = () => {
    this.skip = 0;
    this.setState(state => ({ skip: 0, force: state.force + 1 }));
  }

  handleData = (response, currentData) => {
    currentData = isArray(currentData) ? currentData : [];
    let newData = this.props.extractData(response);
    if (!isArray(newData)) {
      newData = [];
    }
    if (this.skip > 0) {
      newData = currentData.concat(newData);
    }
    this.skip = newData.length;
    this.setState({
      exhausted: newData.length < this.props.limit,
    });
    return newData;
  }

  handleLoadMore = (page) => {
    this.setState(state => ({
      ...state,
      exhausted: true,
      skip: this.skip || this.props.limit * page || 0,
    }));
  }

  renderContent = queryProps => (
    <InfiniteScroll
      data={queryProps.data}
      storageKey="INFINITE_SCROLL_SESSION_DATA"
      loadMore={this.handleLoadMore}
      hasMore={!this.state.exhausted}
      wrapperElement={this.props.element}
      itemElement={this.props.renderItem}
      emptyElement={this.props.renderEmpty}
      itemsPerLoad={this.props.limit}
      isLoading={queryProps.loading}
      queryProps={this.props}
    />
  )

  render() {
    return (
      <Query
        method={this.props.method}
        url={this.props.getUrl(this.props, this.state.skip, this.state)}
        extractData={this.handleData}
        skip={this.state.skip}
        request={this.props.request}
      >
        {
          this.props.rerender
          ? (...args) => this.renderContent(...args)
          : this.renderContent
        }
      </Query>
    );
  }
}

export const ApiBoundInfiniteScrollQuery = forwardRef((props, ref) => {
  const { request } = useContext(ApiContext);
  return <InfiniteScrollQuery ref={ref} request={request} {...props} />;
});
