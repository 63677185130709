import { useEffect, useState } from 'react';
import { useStore } from '../../context/Store';
import { createNamedStyled, keyframes } from '../../stitches.config';

const styled = createNamedStyled('Loader')

// for use in the products list page
export const LoaderWrapper = styled.named('LoaderWrapper')('div', {
  display: 'grid',
  justifyContent: 'center',
  width: '100%',
});

const LoaderContainer = styled.named('LoaderContainer')('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  zIndex: 1,
  flex: '1 1 auto',
});

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const Spinner = styled.named('Spinner')('svg', {
  animation: `${spin} 1s linear infinite`,
});

const Loader = () => {
  const { data: store } = useStore();
  const [showLoader, setShowLoader] = useState(false);

  // only show loader if it takes more than 1 second to load
  useEffect(() => {
    const timeout = setTimeout(() => setShowLoader(true), 1000);

    return () => {
      setShowLoader(false);
      clearTimeout(timeout);
    }
  }, []);

  return (
    <LoaderContainer>
      {showLoader && <Spinner
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="25"
          cy="25"
          r="20"
          stroke={store.accentColorPrimary || store.brandColor}
          strokeWidth="5"
          strokeLinecap="round"
          strokeDasharray="31.415, 31.415"
          fill="none"
        />
      </Spinner>}
    </LoaderContainer>
  );
}

export default Loader;