import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animate } from 'framer-motion';
import Helmet from 'react-helmet';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import Sections from '../../../components/Legacy/Sections';
import renderNode from '../../../builder/functions/renderNode';

import { useEditMode, EditModeUI } from '../../../tools/EditMode';

const styled = createNamedStyled('Page');

const Loader = styled.named('Loader')('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '2px',
  background: 'linear-gradient(to right, $loaderStart, $loaderEnd)',
  zIndex: 1000,
  transformOrigin: 'left',
  willChange: 'transform',

  variants: {
    state: {
      idle: {
        transform: 'scaleX(0)',
      },
      loading: {
        transform: 'scaleX(0.9)',
        transition: 'transform 4000ms $easeOutExpo',
      },
      loaded: {
        opacity: 0,
        transform: 'scaleX(1)',
        transition: 'opacity 300ms, transform 300ms $easeOutCubic',
      },
    },
  },
});

export default function Page({ page, store }) {
  const { sections = [], content, name, _id: pageId } = page || {};

  const {
    isActive,
    setIsActive,
    isEditMode,
    toggleIsEditMode,

    user,
    pageContent,
  } = useEditMode(pageId, content);

  const { navigation } = useTheme();
  const { hash, pathname } = useLocation();

  const [savedHash, setSavedHash] = useState(hash);
  const [loadingState, setLoadingState] = useState('idle');

  useEffect(() => {
    if (loadingState === 'loading') return;
    setLoadingState('loading');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    try {
      document.documentElement.scrollTo({
        top: hash ? window.scrollY
          + document.querySelector(hash).getBoundingClientRect().top
          * getComputedStyle(document.querySelector(hash)).zoom
          - (navigation.position === 'fixed' ? 145 : 0) : 0,
        left: 0,
        behavior: 'instant',
      });
    } catch (error) { /* noop */ }

    setLoadingState('loaded');
    setTimeout(() => setLoadingState('idle'), 300);

    setSavedHash(hash);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (savedHash === hash) return;

    try {
      animate(
        window.scrollY,
        window.scrollY
          + document.querySelector(hash).getBoundingClientRect().top
          * getComputedStyle(document.querySelector(hash)).zoom
          - (navigation.position === 'fixed' ? 145 : 0),
      {
        // TODO: update with theme values
        type: 'spring',
        stiffness: 100,
        damping: 15,
        mass: 0.5,
        onUpdate: v => { window.scroll({ top: v }); },
      });
    } catch (error) { /* noop */ }

    setSavedHash(hash);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  // NOTE: this is a hack to zoom out the
  // iframe on Onboarding / Homepage Picker
  useEffect(() => {
    if (window.self !== window.top) {
      if (window.innerWidth > 768) {
        // TODO: Revisit this nonsense
        document.body.style.zoom = 0.7;
      }
    }
  }, [])
  const logo = store?.logo?.src || store.darkLogo?.src;
  return (
    <>
      <Helmet>
        <title>
          {`${store.name} | ${name}`}
        </title>
              {/* General Meta Tags */}
              {<meta name="title" content={store.metaTitle || name} />}
          {<meta name="description" content={store.metaDescription || name} />}
          {store.metaKeywordsString && <meta name="keywords" content={store.metaKeywordsString} />}

         {/* Open Graph Tags */}
         {<meta property="og:title" content={store.metaTitle || `${store.name} | ${name}`} />}
          { <meta property="og:description" content={store.metaDescription || name} />}
         <meta property="og:url" content={ window.location.href} />
          <meta property="og:type" content="website" />
          {store.name && <meta property="og:site_name" content={store.name || name} />}
          {logo && <meta property="og:image" content={logo} />}
          {store.metaImageAlt && <meta property="og:image:alt" content={store.metaImageAlt || name} />}
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="314" />

          {/* Twitter Cards */}
          {<meta name="twitter:title" content={store.metaTitle || `${store.name} | ${name}`} />}
          { <meta name="twitter:description" content={store.metaDescription || name} />}
          {name && <meta name="twitter:site" content={name} />}
          {name && <meta name="twitter:creator" content={name} />}
       <meta property="twitter:url" content={window.location.href} />
          {logo && <meta name="twitter:image" content={logo} />}

          {/* Locale & App ID */}
          {store.language && store.systemCountry && (
            <meta property="og:locale" content={`${store.language}_${store.systemCountry.toUpperCase()}`} />
          )}

          {/* Alternate Locales */}
          {store.languages &&
            store.languages
              .filter(lang => lang !== store.language)
              .map(language => (
                <meta
                  key={language}
                  property="og:locale:alternate"
                  content={`${language}_${store.systemCountry.toUpperCase()}`}
                />
              ))}
        
      </Helmet>

      <EditModeUI
        pageId={pageId}
        user={user}
        isActive={isActive}
        setIsActive={setIsActive}
        isEditMode={isEditMode}
        toggleIsEditMode={toggleIsEditMode}
      />
      <Loader state={loadingState} />
      <Sections sections={sections} />
      <div id="builder-content">
        {pageContent.map(item => renderNode(item))}
      </div>
    </>
  );
}
