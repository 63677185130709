import React, { useState, useEffect, useCallback } from 'react';

import { createNamedStyled , keyframes} from '../../stitches.config';

import { useApi } from '../../context/Api';

import Types from '../../modules/types';
import { useCart } from '../../context/Cart';

const styled = createNamedStyled('PaymentRequestButton');
import { useTheme } from '../../theme';
import Button from '../../components/Elements/Button';
import { useDictionary } from '../../context/Language';

const Wrapper = styled.named('Wrapper')('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: 'calc($xs / 2)',
  });
  
  const PaymobWrapper = styled.named('Paymob')('div', {
    width: '100%',
    overflow: 'hidden',
    // marginBottom: '-$s',
    marginTop: '$s',
    fontFamily: '$text',
  
    height: '120px',
  
    '*:not(:has(iframe), iframe)': {
      display: 'none',
    },
  
    '[class^="error-message_container"]': {
      fontFamily: '$text',
      textAlign: 'center',
      color: '$error',
      margin: '0 auto $xs',
    },
  });

  const paymentLoading = keyframes({
    '0%': { opacity: 0.7 },
    '100%': { opacity: 0.4 },
  });
  
export default function PaymobCardPayment({ order, store }) {
    const cart = useCart();
    const api = useApi();
    const [isCartSubmitting, setIsCartSubmitting] = useState(false);
    const [formStateError, setFormStateError] = useState(false);
    const {
        cartSubmitting,
        cartSubmit,
        payText,
        orderStatusPendingApproval
  } = useDictionary();
    const {
        language: themeLanguage,
        radii,
        colors,
      } = useTheme();


    useEffect(() => {
        let timeout = null;
        const callback = () => {
          if (!window.Pixel) {
            // eslint-disable-next-line no-console
            console.log('No Paymob Pixel, delaying:', window.Pixel);
            timeout = setTimeout(
              callback,
              500,
            );
            return;
          }

          if (
            order.payment.method === 'CARD'
            && order.payment.provider === 'PAYMOB_PIXEL'
            && order.payment.paymobCardData.paymobClientSecret
          ) {

            const options = {
              publicKey: order.payment.paymobCardData.paymobPublicKey,
              clientSecret: order.payment.paymobCardData.paymobClientSecret,
              paymentMethods: ['card'],
              disablePay: true,
              showPaymobLogo: false,
              elementId: 'paymob-card-element',
              hideCardHolderName: true,
              customStyle: {
                Font_Weight_Label: store?.styleFontLabel?.variantNormal,
                Font_Weight_Input_Fields: store?.styleFontLabel?.variantNormal,
                Color_Border_Input_Fields: store?.brandColor,
                Color_Input_Fields: colors?.backgroundCard,
                Text_Color_For_Input_Fields: colors?.text,
                Color_For_Text_Placeholder: colors?.text,
                Radius_Border: radii.s.replace('px', ''),
              },
              cardValidationChanged : (isValid) => {
                console.log( "Is Valid ?", isValid);
                setFormStateError(isValid);
                isValid === true && setFormStateError(true)
              }
            };
            new Pixel(options);
    
          }
        };
        callback();
        return () => {
          clearTimeout(timeout);
        };
      }, [

        cart
      ]);
   async function handleSubmitPayment() {
        setIsCartSubmitting(true);
         const event = new Event('payFromOutside');
            event.eventName = 'payFromOutside';
            event.initEvent('payFromOutside', true);
            document.dispatchEvent(event);
    }

  return (
    <div className='w-full'>
         <PaymobWrapper id="paymob-card-element" />
         <Button
            // type="submit"
            css={{
              width: '100%',
              maxWidth: 300,
              margin: '0 auto',
              transition: 'opacity 300ms',
              ...isCartSubmitting
              && {
                opacity: 0.7,
                animation: `${paymentLoading} 1000ms 300ms infinite alternate`,
                pointerEvents: 'none',
              },
            }}
            disabled={isCartSubmitting || !formStateError}
            onClick={() => handleSubmitPayment()}
            
          >
            {isCartSubmitting ? orderStatusPendingApproval : payText}
          </Button>
    </div>
  )
}