/* eslint-disable max-len */
import * as React from 'react';

import { useTheme } from '../../theme';
import Wrapper from '../Wrapper';

function Instagram({ type, ...props }) {
  const { custom: { iconsStyle } } = useTheme();

  return (
    <Wrapper
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`type-${iconsStyle}`}
      {...props}
    >
      {iconsStyle === 'duotone' || iconsStyle === 'duotoneOffset' ? (
        <path
          d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
          fill="#000"
          stroke="#000"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="duotone"
          opacity={0.6}
        />
      ) : null}
      {iconsStyle === 'solid' ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
          fill="#000"
          className="solid"
        />
      ) : null}
      <path
        d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
        className="base"
      />
    </Wrapper>
  );
}

export default Instagram;
