import getKey from 'lodash/get';
import setKey from 'lodash/set';
import isFunction from 'lodash/isFunction';
import moment from 'moment-timezone';

import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';
import TypesSystemCountry from './types.system-country';
import TypesCommon from './types.common';

const ITEM_CLO_PATHS_PARTY_DETAILS_ADDRESS = [
  'line1',
  'line2',
  'lines',
  // 'country',
  'city',
  // 'postalCode',
  'district',
  'area',
  'neighbourhood',
  'streetName',
  'streetNumber',
].map(path => ({
  originPath: path,
  destinationPath: `i18nCLO.${path}`,
}));

const I18N_CLO_PATHS_PARTY_DETAILS = [
  ...[
    'name',
    'firstName',
    'lastName',
  ].map(path => ({
    originPath: path,
    destinationPath: `i18nCLO.${path}`,
  })),
  ...ITEM_CLO_PATHS_PARTY_DETAILS_ADDRESS.map(
    ({ originPath, destinationPath }) => ({
      originPath: `address.${originPath}`,
      destinationPath: `address.${destinationPath}`,
    }),
  ),
];

const createGetter = ({
  get,
  key,
  keyFallback,
}) => (order, columnValue = {}) => {
  if (get === 'STATIC') {
    const raw = columnValue.raw || '';
    return (typeof raw === 'string' ? raw : '');
  }
  let value = getKey(order, key);
  if (keyFallback && typeof value === 'undefined') {
    value = getKey(order, keyFallback);
  }
  if (get === 'AMOUNT') {
    const { currency } = TypesSystemCountry.getSystemCountry(
      order.systemCountry,
    );
    value = `${currency} ${
      TypesHelpers.decimalize((Number.isFinite(value) ? value : 0))
    }`;
  } else if (get === 'FLOAT') {
    value = `${TypesHelpers.decimalize(
      (Number.isFinite(value) ? value : 0)
    )}`;
  } else if (get === 'INT') {
    value = `${TypesHelpers.decimalizeInt(
      (Number.isFinite(value) ? value : 0)
    )}`;
  }
  return value;
};

const Types = {};

Object.assign(
  Types,
  TypesHelpers.createValues(
    'SHIPPING_COMPANIES_VALUE_SEPARATOR',
    [{
      id: 'SPACE',
      label: 'Space',
      value: ' ',
    }, {
      id: 'COMMA',
      label: 'Comma',
      value: ', ',
    }, {
      id: 'DASH',
      label: 'Dash (-)',
      value: '-',
    }, {
      id: 'UNDERSCORE',
      label: 'Underscore (_)',
      value: '_',
    }, {
      id: 'SLASH',
      label: 'Slash (/)',
      value: ' / ',
    }, {
      id: 'DOT',
      label: 'Dot',
      value: '.',
    }],
  ),
  TypesHelpers.createValues(
    'SHIPPING_COMPANIES_EXPORT_VALUES',
    [
      {
        id: 'STATIC',
        key: 'STATIC',
        label: 'Static Value',
        get: 'STATIC',
      },
      {
        id: 'ORDER_ID',
        key: '_id',
        label: 'Order ID',
      }, {
        id: 'ORDER_ITEMS',
        key: 'items',
        label: 'Order Items',
        get: (order) => (
          order
          .items
          ?.map(item => `${item.quantity} x ${item.name}`)
          .join(', ')
        ),
      }, {
        id: 'COST_CURRENCY',
        key: 'systemCountry',
        label: 'Cost | Currency ',
        get: (order) => (
          TypesSystemCountry.getSystemCountry(order.systemCountry)?.currency
        ),
      }, {
        id: 'COST_CHARGED',
        key: 'cost.charged',
        label: 'Cost | Charged',
        get: 'FLOAT',
      }, {
        id: 'COST_CHARGED_FORMATTED',
        key: 'cost.charged',
        label: 'Cost | Charged With Currency',
        get: 'AMOUNT',
      }, {
        id: 'COST_COD',
        key: 'cost.cod',
        label: 'Cost | COD',
        get: 'FLOAT',
      }, {
        id: 'COST_COD_FORMATTED',
        key: 'cost.cod',
        label: 'Cost | COD With Currency',
        get: 'AMOUNT',
      }, {
        id: 'COST_COD_REQUIRED',
        key: 'cost.cod',
        label: 'Cost | COD Required',
        get: (order, columnValue) => (
          getKey(order, 'cost.cod', 0) > 0
          ? getKey(columnValue, 'valueIf', '')
          : getKey(columnValue, 'valueElse', '')
        ),
      }, {
        id: 'COST_CUSTOMS_VALUE',
        key: 'cost.charged',
        label: 'Cost | Customs Value',
        get: (order, columnValue) => TypesHelpers.decimalize(Math.min(
          (
            getKey(order, 'cost.charged', 0)
            / getKey(columnValue, 'valueDivisor', 1)
          ),
          getKey(columnValue, 'valueDividedMax', 1000)
        )),
      },
      ...TypesCommon.PRODUCT_ATTRIBUTE_ITEMS.map(({ id, label }) => ({
        id: `SHIPPING_ITEMS_${id}`,
        key: 'order.attributes',
        label: `Shipping | ${label}`,
        get: (order, columnValue) => (
          getKey(order, 'attributes', [])
          .includes(TypesCommon.PRODUCT_ATTRIBUTE_CONST[id])
          ? getKey(columnValue, 'valueIf', '')
          : getKey(columnValue, 'valueElse', '')
        ),
      })),
      {
        id: 'DATES_DATE_CREATED',
        key: 'createdAt',
        label: 'Dates | Date Created',
        get: (order, column) => {
          const createdAt = getKey(order, 'createdAt', moment());
          const format = getKey(column, 'raw', 'DD-MM-YYYY');
          return moment(createdAt).format(format);
        },
      },
      {
        id: 'DATES_TODAY',
        key: 'today',
        label: 'Dates | Today',
        get: (order, column) => {
          const format = getKey(column, 'raw', 'DD-MM-YYYY');
          return moment().format(format);
        },
      },
      {
        id: 'DATES_TOMORROW',
        key: 'tomorrow',
        label: 'Dates | Tomorrow',
        get: (order, column) => {
          const format = getKey(column, 'raw', 'DD-MM-YYYY');
          return moment().add('1d').format(format);
        },
      },
      ...[
        [
          'Customer',
          'shippingDetails',
          [
            'STORE_NAME',
          ],
          true,
        ],
        [
          'Pickup',
          'pickupDetails',
          [
            'FIRST_NAME',
            'LAST_NAME',
            'ADDRESS_DISTRICT',
            'ADDRESS_AREA',
            'ADDRESS_NEIGHBOURHOOD',
            'ADDRESS_STREET_NAME',
            'ADDRESS_STREET_NUMBER',
            'COMMENT',
          ],
          true,
        ],
      ].reduce(
        (agr, [label, keyPrefix, skip = [], i18nCLOEnabled = false]) => {
          const idPrefix = `${label.toUpperCase()}`;
          const labelPrefix = `${label}`;
          [{
            id: 'NAME',
            key: 'name',
            label: '| Name',
          }, {
            id: 'STORE_NAME',
            key: 'store.name',
            label: '| Store Name',
            get: (order) => {
              const value = getKey(order, 'store.name');
              return value;
            },
          }, {
            id: 'FIRST_NAME',
            key: 'firstName',
            label: '| First Name',
          }, {
            id: 'LAST_NAME',
            key: 'lastName',
            label: '| Last Name',
          }, {
            id: 'PHONE_NUMBER',
            key: 'phoneNumberString',
            label: '| Phone Number',
          }, {
            id: 'PHONE_NUMBER_DIAL_CODE',
            key: 'phoneNumber.code',
            label: '| Phone Number Dial Code',
          }, {
            id: 'PHONE_NUMBER_NATIONAL',
            key: 'phoneNumber.number',
            label: '| Phone Number National',
          }, {
            id: 'EMAIL',
            key: 'email',
            label: '| Email',
          }, {
            id: 'ADDRESS_COUNTRY',
            key: 'address.country',
            label: 'Address | Country',
            get: (order, column, config = {}) => {
              const value = getKey(
                order,
                `${config.key}`,
                order.systemCountry,
              );
              return TypesInternationalization.COUNTRIES_MAP[value]?.name;
            },
          }, {
            id: 'ADDRESS_LINE1',
            key: 'address.line1',
            label: 'Address | Formatted Address',
          }, {
            id: 'ADDRESS_LINE2',
            key: 'address.line2',
            label: 'Address | Additional Info',
          }, {
            id: 'ADDRESS_LINES',
            key: 'address.lines',
            label: 'Address | Formatted Address + Additional Info',
            get: (order, column, config = {}) => {
              const value = getKey(order, config.key, order.systemCountry);
              const address = order.shippingDetails.address;
              const i18nAddress = address.i18nCLO || {}; // Use i18n address if available
            
              const {
                streetName = address.streetName,
                streetNumber = address.streetNumber,
                area = address.area,
                line2 = address.line2,
                city = address.city,
              } = i18nAddress;
            
              const country = TypesInternationalization.COUNTRIES_MAP[value]?.name || "";
            
              return [streetName, streetNumber, area, city, country, line2]
                .filter(Boolean) // Remove falsy values (null, undefined, empty string)
                .join(" ") // Concatenate with a space
                .trim(); // Remove trailing spaces
            }
          }, {
            id: 'ADDRESS_DISTRICT',
            key: 'address.district',
            label: 'Address | District',
          }, {
            id: 'ADDRESS_CITY',
            key: 'address.city',
            label: 'Address | City',
          }, {
            id: 'ADDRESS_POSTAL_CODE',
            key: 'address.postalCode',
            label: 'Address | Postal Code',
          }, {
            id: 'ADDRESS_AREA',
            key: 'address.area',
            label: 'Address | Area',
          }, {
            id: 'ADDRESS_NEIGHBOURHOOD',
            key: 'address.neighbourhood',
            label: 'Address | Neighbourhood',
          }, {
            id: 'ADDRESS_STREET_NAME',
            key: 'address.streetName',
            label: 'Address | Street Name',
          }, {
            id: 'ADDRESS_STREET_NUMBER',
            key: 'address.streetNumber',
            label: 'Address | Street Number',
          }, {
            id: 'ADDRESS_LAT',
            key: 'address.coordinates.1',
            label: 'Address | Lat',
          }, {
            id: 'ADDRESS_LNG',
            key: 'address.coordinates.0',
            label: 'Address | Lng',
          }, {
            id: 'COMMENT',
            key: 'comment',
            label: '| Comment',
            get: (order) => {
              const value = getKey(order, 'comment');
              return value;
            },
          }]
          .forEach((item) => {
            if (skip.includes(item.id)) {
              return null;
            }
            item._id = item.id;
            item.id = `${idPrefix}_${item._id}`;
            item._key = item.key;
            item.key = `${keyPrefix}.${item._key}`;
            item.label = `${labelPrefix} ${item.label}`;
            agr.push(item);
            if (i18nCLOEnabled) {
              const i18nCLOConfig = I18N_CLO_PATHS_PARTY_DETAILS
              .find(
                testItem => (
                  `${item.key}` === `${keyPrefix}.${testItem.originPath}`
                )
              );
              if (i18nCLOConfig) {
                const extraItem = { ...item };
                extraItem.id = `${item.id}_I18NCLO`;
                extraItem.key = `${keyPrefix}.${i18nCLOConfig.destinationPath}`;
                extraItem.keyFallback = `${keyPrefix}.${item._key}`;
                extraItem.label = `${item.label} (English)`;
                agr.push(extraItem);
              }
            }
            return item;
          });
          return agr;
        },
        [],
      ),
    ].map((item) => {
      item.label = `${item.label}`;
      if (!isFunction(item.get)) {
        item.get = createGetter(item);
      } else {
        // There is no need to actually do anything here
        // item.get = order => item.get(order, item);
      }
      return item;
    }),
  ),
  TypesHelpers.createValues(
    'SHIPPING_COMPANIES_IMPORT_VALUES',
    [{
      id: 'ORDER_ID',
      key: 'order',
      name: 'orderId',
      label: 'Set Import Order ID',
      labelShort: 'Order ID',
    }, {
      id: 'ACTION',
      key: 'action',
      name: 'status',
      label: 'Set Import Action Based On Column Value',
      labelShort: 'Action',
      set: (result, config, value, columnValue) => {
        let setAction = null;
        let setActionAny = null;
        if (Array.isArray(columnValue?.actionValuesMap)) {
          for (let i = 0; i < columnValue.actionValuesMap.length; i++) {
            const columnActionValue = columnValue.actionValuesMap[i];
            if (columnActionValue?.action) {
              const { action } = columnActionValue;
              if (action) {
                let { value: testValue } = columnActionValue;
                if (typeof testValue === 'string') {
                  testValue = testValue.trim().toLowerCase();
                  if (testValue === '*') {
                    setActionAny = action;
                  } else if (testValue === value.toLowerCase()) {
                    setAction = action;
                  }
                }
              }
            }
          }
        }
        if (setAction || setActionAny) {
          setKey(
            result,
            config.key,
            setAction || setActionAny,
          );
          return true;
        }
        return false;
      },
    }, {
      id: 'SHIPPING_REFERENCE_ID',
      name: 'referenceId',
      label: 'Set Shipping | Reference ID',
      labelShort: 'Reference ID',
    }, {
      id: 'SHIPPING_REFERENCE_CODE',
      name: 'referenceCode',
      label: 'Set Shipping | Reference Code (Barcode / QRCode)',
      labelShort: 'Reference Code',
    }, {
      id: 'SHIPPING_TRACKING_ID',
      name: 'trackingId',
      label: 'Set Shipping | Tracking ID',
      labelShort: 'Tracking ID',
    }, {
      id: 'SHIPPING_TRACKING_URL',
      name: 'trackingUrl',
      label: 'Set Shipping | Tracking URL',
      labelShort: 'Tracking URL',
    }].map((item) => {
      item.label = `${item.label}`;
      if (!item.key) {
        item.key = (
            item.id === 'ACTION' ? 'action'
          : item.id === 'ORDER_ID' ? 'order'
          : `values.${item.id}`
        );
      }
      if (!isFunction(item.set)) {
        item.set = (result, config, value, _columnValue) => {
          if (typeof value === 'string' && value.length) {
            setKey(
              result,
              config.key,
              value,
            );
            return true;
          }
          return false;
        };
      }
      return item;
    }),
  ),
);

export default Types;
