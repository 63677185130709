import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animate } from 'framer-motion';
import Helmet from 'react-helmet';

import { createNamedStyled } from '../../../stitches.config';
import { useTheme } from '../../../theme';

import packmanLogo from '../../../assets/icons/packman-new-logo.png';
import packmanLogoText from '../../../assets/icons/packman-new-logo-txt.png';

import { useEditMode, EditModeUI } from '../../../tools/EditMode';
import { Paragraph } from '../../../components/Elements/Text';
import styles from './Page.module.css';
import { padding } from 'polished';
import { useDictionary } from '../../../context/Language';

const styled = createNamedStyled('Page');
const DivWrapper = styled.named('DivWrapper')('div', {
  background: '#fff !important',
  width: '100vw',
  minHeight: '100vh', 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

});

const CardWapper = styled.named('CardWapper')('div', {
  flexGrow: 1,

  width: '100%',
  height: '100%',
  display: 'flex',
  gap: '50px',
  padding: '50px',
  flexDirection: 'column',
  // justifyContent: 'space-evenly',
  alignItems: 'center',
  flex: 1,
  '@media (max-width: 768px)': {
    padding: '20px 20px',
  },

});
const CardBody = styled.named('CardBody')('div', {
  width: '100%',
  height: '55vh',
  display: 'flex',
  
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(90deg, #12304f, #215487)',
  borderRadius: '40px',
  '@media (max-width: 768px)': {
    borderRadius: '24px',
    padding: '10px',
    height: '45vh',
  },

});

const Loader = styled.named('Loader')('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '2px',
  background: 'linear-gradient(to right, $loaderStart, $loaderEnd)',
  zIndex: 1000,
  transformOrigin: 'left',
  willChange: 'transform',

  variants: {
    state: {
      idle: {
        transform: 'scaleX(0)',
      },
      loading: {
        transform: 'scaleX(0.9)',
        transition: 'transform 4000ms $easeOutExpo',
      },
      loaded: {
        opacity: 0,
        transform: 'scaleX(1)',
        transition: 'opacity 300ms, transform 300ms $easeOutCubic',
      },
    },
  },
});

const RatingIcon = styled.named('RatingIcon')('i', {
  color: '$brandColor',
});
export default function Page({ page, store }) {
  const { sections = [], content, name, _id: pageId } = page || {};

  const {
    isActive,
    setIsActive,
    isEditMode,
    toggleIsEditMode,

    user,
    pageContent,
  } = useEditMode(pageId, content);

  const { navigation } = useTheme();
  const { hash, pathname } = useLocation();

  const [savedHash, setSavedHash] = useState(hash);
  const [loadingState, setLoadingState] = useState('idle');

  useEffect(() => {
    if (loadingState === 'loading') return;
    setLoadingState('loading');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const isArabic = pathname.includes('/ar');


  const {packmanName, storeNotAvailableText, exploreOtherStores, startAfreeTrial, areYouStoreOwner, havingTroubleGettingIntoStore,
    loggingInText,
    contactPackmanSupport,
    OpenNewPackmanStore,
    startFreeTrial,
    signupNow,
    footerPoweredByTxt,
  } = useDictionary();
  return (
    <>
      {/* <EditModeUI
        pageId={pageId}
        user={user}
        isActive={isActive}
        setIsActive={setIsActive}
        isEditMode={isEditMode}
        toggleIsEditMode={toggleIsEditMode}
      /> */}
      {/* <Loader state={loadingState} /> */}
      {/* <Sections sections={sections} /> */}
      <div id="builder-content">
        <div>
          <DivWrapper >
            <CardWapper>

              <CardBody>
                <a href="https://packman.ai"  target='_blank'>
                <div className={styles.packmanLinkContainer}>
                  <i class={`fa fa-arrow-${isArabic ? 'right': 'left'}`}></i>
                  <Paragraph className={styles.packmanLink} small >{packmanName.toUpperCase()}</Paragraph>
                </div></a>
                
                <Paragraph className={styles.pageText} >{storeNotAvailableText}</Paragraph>
                <div className='flex gap-3 flex-col sm:flex-row text-center justify-center w-full px-5 sm:px-20'>
                  <button className={styles.fullButton}><Paragraph className={styles.fullButtonText}>{exploreOtherStores}</Paragraph></button>
                  <a href="https://shop.packman.app/admin/" target='_blank'>
                  <button className={styles.borderedButton}><Paragraph className={styles.borderedButtonText}>{startAfreeTrial}</Paragraph></button>
                  </a>
                </div>

              </CardBody>
              <div className={styles.promotionCardsWrapper}>
                <div className={styles.promotionCards}>
                  <Paragraph as={'h3'}>{areYouStoreOwner}</Paragraph>
                  <Paragraph>
                    {havingTroubleGettingIntoStore}
                    <a href='https://shop.packman.app/admin' className={styles.forgotPasswordTxt}  target='_blank'> {loggingInText}</a>
                   <Paragraph> {contactPackmanSupport}</Paragraph>
                  </Paragraph>
                </div>
                <div className={styles.promotionCards}>
                  <Paragraph as={'h3'}>{OpenNewPackmanStore}</Paragraph>
                  <Paragraph>
                 {startFreeTrial}
                  </Paragraph>
                  <Paragraph className={styles.signupText} as={'a'} href={'https://shop.packman.app/admin'}  target='_blank'>{signupNow}</Paragraph>
                </div>
              </div>

            </CardWapper>

            <div className='flex items-center' style={{ margin: '0 0 10px 0' }}>
              <Paragraph small className={styles.poweredBy}>
                {footerPoweredByTxt}
                </Paragraph>
                <img src={packmanLogo} width={36} height={36} />
                <Paragraph><span className={styles.packmanName}>{packmanName}</span></Paragraph>
             
              {/* <Paragraph className={styles.poweredByPackman}>Packman</Paragraph> */}
            </div>

          </DivWrapper>
        </div>

        {/* {pageContent.map(item => renderNode(item))} */}
      </div>
    </>
  );
}
